

export const shake:any = {
    hidden: {
      x : 0
    },
    animate: {
      x : [0, -20, 40, -20, 40, -20]
    },
    transition : {
      duration: 0.1,
	  ease : 'easeIn'
    }
};

